<template>
  <div>
    <div v-if="type !== 'default'" class="d-flex justify-content-between mb-0 font-weight-medium poppins-font text-black text-capitalizecase">
      <span>
        <span class="text-capitalize">{{ name }}</span>
        {{ $t('salon-services.dynamic-form-label.with') }}
        <span class="text-lowercase" style="text-decoration: underline">
          {{ $t(`salon-services.dynamic-form-label.${type}`) }}
        </span>
      </span>

      <div v-if="activated" class="checkbox mb-1-5">
        <label class="mb-0 d-inline-block cursor-pointer">
        <input v-model="useCombinationBreak" type="checkbox" />
        <i class="input-helper"></i>
        <span class="mb-0 text-black" style="line-height: 24px">
          {{ $t('salon-services.dynamic-form-label.break') }}
        </span>
        </label>
      </div>
    </div>
    <div class="row" :class="!hasAddCombinationButton ? 'mx-0' : ''">
      <div v-if="hasAddCombinationButton" class="col-12 col-lg-6">
        <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
          {{ $t('salon-services.dynamic-form-label.age_range') }}
        </label>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[10].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[10].tag === 'select'"
                :id="fields[10].name"
                v-model="fields[10].value"
                v-validate="validateCondition.length ? fields[10].validateRules : ''"
                :data-vv-as="$t(fields[10].i18KeyLabel)"
                :name="fields[10].name"
                :disabled="!activated || dynamicFields.length > 0"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[10].data" :key="`top-${key}`" :value="option.id">{{
                  option.value
                }}</option>
              </select>
            </div>
            <span class="text-gray mx-lg-2-5">—</span>
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[11].i18KeyLabel) }}
            </p>

            <div class="input-group position-relative small-input">
              <select
                v-if="fields[11].tag === 'select'"
                :key="fields[11].name"
                :id="fields[11].name"
                v-model="fields[11].value"
                v-validate="validateCondition.length ? fields[11].validateRules : ''"
                :data-vv-as="$t(fields[11].i18KeyLabel)"
                :name="fields[11].name"
                :disabled="!activated || dynamicFields.length > 0"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option
                  v-for="(option, key) in ageFromDataByTypeAndToValue(fields[10].value, type)"
                  :key="`top-${key}`"
                  :value="option.id"
                  >{{ option.value }}</option
                >
              </select>
            </div>
          </div>
          <span v-if="errors.has(fields[10].name) || errors.has(fields[11].name)" class="error-message mt-1 pl-0">
            {{ errors.first(fields[10].name) || errors.first(fields[11].name) }}
          </span>
        </div>
      </div>
      <div
        v-if="useDynamicPricing && fields[0].tag === 'input'"
        :class="[
          useDynamicPricing === 0 && hasAddCombinationButton ? 'col-6' : '',
          useDynamicPricing !== 0 && hasAddCombinationButton ? 'col-6 col-lg-3' : '',
          useDynamicPricing !== 0 && !hasAddCombinationButton ? 'col-6 col-xl-2' : ''
        ]"
      >
        <div>
          <label :for="`${fields[0].name}`" class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">{{
            $t(fields[0].i18KeyLabel)
          }}</label>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text text-gray bg-white px-0-5 py-0-8 text-small font-weight-medium">
                CHF
              </span>
            </div>
            <input
              :id="fields[0].name"
              :key="fields[0].name"
              v-model="fields[0].value"
              v-validate="validateCondition.length ? fields[0].validateRules : ''"
              :min="fields[0].min"
              :max="fields[0].max"
              :step="fields[0].step"
              :data-vv-as="$t(fields[0].i18KeyLabel)"
              :name="fields[0].name"
              :type="fields[0].type"
              :placeholder="fields[0].placeholder"
              :disabled="!activated"
              class="form-control w-100 px-1-5"
            />
          </div>
        </div>
      </div>

      <div
        v-if="fields[1].tag === 'input'"
        :class="[
          useDynamicPricing === 0 && hasAddCombinationButton ? 'col-12 col-lg-6' : '',
          useDynamicPricing === 0 && !hasAddCombinationButton ? 'col-12 col-lg-6' : '',
          useDynamicPricing !== 0 && hasAddCombinationButton ? 'col-6 col-lg-3' : '',
          useDynamicPricing !== 0 && !hasAddCombinationButton ? 'col-6 col-xl-2' : ''
        ]"
      >
        <div>
          <label :for="`${fields[1].name}`" class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">{{
            $t(fields[1].i18KeyLabel)
          }}</label>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text text-gray bg-white px-0-5 py-0-8 text-small font-weight-medium">
                CHF
              </span>
            </div>
            <input
              :id="fields[1].name"
              :key="fields[1].name"
              v-model="fields[1].value"
              v-validate="
                useDynamicPricing
                  ? validateCondition.length
                    ? `${fields[1].validateRules}|min_value:${+fields[0].value}`
                    : ''
                  : `${fields[1].validateRules}|min_value:5`
              "
              :min="fields[1].min"
              :max="fields[1].max"
              :step="fields[1].step"
              :data-vv-as="$t(fields[1].i18KeyLabel)"
              :name="fields[1].name"
              :type="fields[1].type"
              :placeholder="fields[1].placeholder"
              :disabled="!activated"
              class="form-control w-100 px-1-5"
            />
          </div>
        </div>
      </div>

      <div v-if="!useServiceBreak && !useCombinationBreak" class="col-12 col-lg-6">
        <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
          {{ $t('salon-services.dynamic-form-label.duration') }}
        </label>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[2].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[2].tag === 'select'"
                :key="fields[2].name"
                :id="fields[2].name"
                v-model="fields[2].value"
                v-validate="!validateBreakFields ? fields[2].validateRules : ''"
                :data-vv-as="$t(fields[2].i18KeyLabel)"
                :name="fields[2].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[2].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>

            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[3].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[3].tag === 'select'"
                :key="fields[3].name"
                :id="fields[3].name"
                v-model="fields[3].value"
                v-validate="!validateBreakFields ? fields[3].validateRules : ''"
                :data-vv-as="$t(fields[3].i18KeyLabel)"
                :name="fields[3].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[3].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="useServiceBreak || useCombinationBreak" class="col-12 col-lg-6">
        <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
          {{ $t('salon-services.dynamic-form-label.duration_break') }}
        </label>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[4].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[4].tag === 'select'"
                :key="fields[4].name"
                :id="fields[4].name"
                v-model="fields[4].value"
                v-validate="validateBreakFields ? fields[4].validateRules : ''"
                :data-vv-as="$t(fields[4].i18KeyLabel)"
                :name="fields[4].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[4].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>

            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[5].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[5].tag === 'select'"
                :key="fields[5].name"
                :id="fields[5].name"
                v-model="fields[5].value"
                v-validate="validateBreakFields ? fields[5].validateRules : ''"
                :data-vv-as="$t(fields[5].i18KeyLabel)"
                :name="fields[5].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[5].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div v-if="useServiceBreak || useCombinationBreak" class="col-12 col-lg-6 col-xl-6">
        <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
          {{ $t('salon-services.dynamic-form-label.duration_before_break') }}
        </label>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[6].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[6].tag === 'select'"
                :key="fields[6].name"
                :id="fields[6].name"
                v-model="fields[6].value"
                v-validate="validateBreakFields ? fields[6].validateRules : ''"
                :data-vv-as="$t(fields[6].i18KeyLabel)"
                :name="fields[6].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[6].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>

            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[7].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[7].tag === 'select'"
                :id="fields[7].name"
                :key="fields[7].name"
                v-model="fields[7].value"
                v-validate="validateBreakFields ? fields[7].validateRules : ''"
                :data-vv-as="$t(fields[7].i18KeyLabel)"
                :name="fields[7].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[7].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>


      <div v-if="useServiceBreak || useCombinationBreak" class="col-12 col-lg-6">
        <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
          {{ $t('salon-services.dynamic-form-label.duration_after_break') }}
        </label>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[8].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[8].tag === 'select'"
                :id="fields[8].name"
                :key="fields[8].name"
                v-model="fields[8].value"
                v-validate="validateBreakFields ? fields[8].validateRules : ''"
                :data-vv-as="$t(fields[8].i18KeyLabel)"
                :name="fields[8].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[8].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>

            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(fields[9].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="fields[9].tag === 'select'"
                :key="fields[9].name"
                :id="fields[9].name"
                v-model="fields[9].value"
                v-validate="validateBreakFields ? fields[9].validateRules : ''"
                :data-vv-as="$t(fields[9].i18KeyLabel)"
                :name="fields[9].name"
                :disabled="!activated"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in fields[9].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>


      <div v-if="allowClear && !hasAddCombinationButton" class="col d-flex flex-wrap action-combination mt-1-5">
        <button class="btn btn-checkbox px-0-7 py-0-5 mr-0 ml-auto mt-auto mb-0" @click="resetCombinationEmit()">
          <font-awesome-icon :icon="['far', 'times']" class="fa-w-18" style="font-size: 18px;"></font-awesome-icon>
        </button>
      </div>

      <div v-if="showAddCombinationButton" class="col-12 d-flex justify-content-end mt-1-5">
        <div class="action-combination align-items-center mt-auto mb-0" :class="{ 'd-flex': !activated }">
          <button class="btn btn-checkbox active" style="line-height: 24px:" @click="addFirstDynamicCombinationEmit()">
            <span class="text-black mr-0-5">
              {{ $t('salon-services.dynamic-form-label.add_button') }}
            </span>
            <font-awesome-icon :icon="['far', 'plus']" class="fa-w-16 text-primary"></font-awesome-icon>
          </button>

          <button class="btn btn-checkbox mt-auto px-0-7 py-0-5 ml-1" @click="resetCombinationEmit()">
            <font-awesome-icon :icon="['far', 'times']" class="fa-w-18" style="font-size: 18px;"></font-awesome-icon>
          </button>
        </div>
      </div>

      <div class="col-12 py-0">
        <span
          v-if="
            errors.has(fields[0].name) ||
              errors.has(fields[1].name) ||
              errors.has(fields[2].name) ||
              errors.has(fields[3].name) ||
              errors.has(fields[4].name) ||
              errors.has(fields[5].name) ||
              errors.has(fields[6].name) ||
              errors.has(fields[7].name) ||
              errors.has(fields[8].name) ||
              errors.has(fields[9].name)
          "
          class="error-message mt-1 pl-0"
        >
          {{
            errors.first(fields[0].name) ||
              errors.first(fields[1].name) ||
              errors.first(fields[2].name) ||
              errors.first(fields[3].name) ||
              errors.first(fields[4].name) ||
              errors.first(fields[5].name) ||
              errors.first(fields[6].name) ||
              errors.first(fields[7].name) ||
              errors.first(fields[8].name) ||
              errors.first(fields[9].name)
          }}
        </span>
      </div>
    </div>

    <div class="app-fee font-weight-medium">
      <div class="border-bottom mx-n1-5 my-1-5"></div>
    </div>

    <div v-if="hasAddCombinationButton">
      <div v-for="(dynamicField, key) in dynamicFields" :key="`${type}-field-dynamic-${key}`" class="row mx-n1">
        <DynamicCombinationBlock
          :ref="`${type}DynamicField`"
          :use-dynamic-pricing="useDynamicPricing"
          :activated-block="activated"
          :field-type="type"
          :form-fields="dynamicField"
          :is-last="dynamicFields.length - 1 === key"
          :fields-group-key="key"
          :age-values="ageValues"
          :dynamic-fileds-length="dynamicFields.length"
          :gender-id="genderId"
          @update-free-age-values="updateFreeAgeValuesDynamicFieldsEmit"
          @add-dynamic-combination="addDynamicCombinationEmit"
          @delete-dynamic-combination="deleteDynamicCombinationEmit"
        ></DynamicCombinationBlock>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicCombinationBlock from '@/components/salon/services/DynamicCombinationBlock'

import { mask } from 'vue-the-mask'

export default {
  name: 'CombinationBlockFields',
  components: { DynamicCombinationBlock },
  directives: { mask },
  props: {
    useDynamicPricing: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    activated: Boolean,
    useServiceBreak: Boolean,
    hasAddCombinationButton: Boolean,
    fields: {
      type: Array,
      default: () => []
    },
    cachedFields: {
      type: String,
      default: ''
    },
    dynamicFields: {
      type: Array,
      default: () => []
    },
    ageValues: {
      type: Array,
      required: true
    },
    genderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      useCombinationBreak: false
    }
  },
  computed: {
    validateCondition() {
      return this.fields.filter(field => field.value !== '')
    },
    validateBreakFields() {
      return (this.useServiceBreak || this.useCombinationBreak) && this.validateCondition.length
    },
    fieldNotSelected() {
      const durationKeys = [2,3]
      const breakKeys = [4,5,6,7,8,9]

      return this.fields.filter((el, key) => {
        if ((this.useServiceBreak || this.useCombinationBreak) && durationKeys.includes(key)) {
          return false
        }

        if ((!this.useServiceBreak && !this.useCombinationBreak) && breakKeys.includes(key)) {
          return false
        }

        if (this.useDynamicPricing === 0 && key === 0) {
          return false
        } else {
          return el.value === ''
        }
      }).length
    },
    allowClear() {
      return this.cachedFields !== JSON.stringify(this.fields)
    },
    showAddCombinationButton() {
      let mainCondition =
        this.hasAddCombinationButton && this.activated && this.dynamicFields.length === 0 && !this.fieldNotSelected

      return mainCondition && !this.emptyAgeData
    },
    emptyAgeData() {
      let fromVal = this.fields[10].value
      let toVal = this.fields[11].value
      let formSelectedIsFirst = this.fields[11].data.findIndex(el => el.id === fromVal) === 0
      let toSelectedIsLast = this.fields[11].data.findIndex(el => el.id === toVal) === this.fields[11].data.length - 1

      return formSelectedIsFirst && toSelectedIsLast
    }
  },
  watch: {
    activated(val) {
      if (!val) {
        this.errors.clear()
      }
    },
    useCombinationBreak(val) {
      const durationKeys = [2,3]
      const breakKeys = [4,5,6,7,8,9]

      if (val) {
        durationKeys.forEach(el => this.fields[el].value = "")
      } else {
        breakKeys.forEach(el => this.fields[el].value = "")
      }
      
      this.$nextTick(()=> this.$validator.validateAll())
    },
    useServiceBreak(val) {
      if (this.type === 'default') {
        this.useCombinationBreak = val
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      if (this.fields[4].value !== '') {
        this.useCombinationBreak = true
      }
    })
  },
  methods: {
    ageFromDataByTypeAndToValue(toValue, type) {
      return this.fields[11].data.filter(item => item.id >= toValue)
    },
    addFirstDynamicCombinationEmit() {
      this.$emit('add-first-dynamic-combination', this.type)
    },
    addDynamicCombinationEmit() {
      this.$emit('add-dynamic-combination', this.type)
    },
    updateFreeAgeValuesDynamicFieldsEmit(data) {
      this.$emit('update-free-age-values-dynamic-fields', data)
    },
    deleteDynamicCombinationEmit(data) {
      this.$emit('delete-dynamic-combination', data)
    },
    resetCombinationEmit() {
      let field = this.fields.find(field => field.id !== undefined)

      let refreshParams = {
        type: this.type
      }

      if (field !== undefined) {
        refreshParams = {
          ...refreshParams,
          ...{ id: field.id }
        }
      }

      this.$emit('reset-combination', refreshParams)
    }
  }
}
</script>
