<template>
  <div class="combination card pt-1-5 px-1-5 mt-2-5" :class="{ 'select-combination': activated }">
    <div class="d-flex justify-content-between">
      <div class="checkbox mb-1-5">
        <label class="mb-0 d-inline-block cursor-pointer">
          <input v-model="activate" type="checkbox" />
          <i class="input-helper"></i>
          <span class="h6 mb-0 text-black" style="line-height: 24px">
            {{ $t('salon-services.dynamic-form-label.type_text') }}
            <span class="text-primary">{{ name }}</span>
            {{ $t(additionalText) }}
          </span>
        </label>
      </div>

      <div v-if="activated && isDefaultCombination" class="checkbox mb-1-5">
        <label class="mb-0 d-inline-block cursor-pointer">
          <input v-model="useServiceBreak" type="checkbox" />
          <i class="input-helper"></i>
          <span class="mb-0 text-black font-weight-medium" style="line-height: 24px">
            {{ $t('salon-services.dynamic-form-label.break') }}
          </span>
        </label>
      </div>
    </div>

    <b-button-group v-if="isHairServiceType" size="md">
      <b-button
        :disabled="!activated"
        variant="checkbox"
        :class="{ active: !isDefaultCombination }"
        class="h-auto py-1"
        @click="changeTypeCombination(false)"
      >
        <span>{{ $t('salon-services.dynamic-form-label.combinations') }}</span>
      </b-button>
      <b-button
        :disabled="!activated"
        variant="checkbox"
        :class="{ active: isDefaultCombination }"
        class="py-1"
        @click="changeTypeCombination(true)"
      >
        <span>{{ $t('salon-services.dynamic-form-label.default_price') }}</span>
      </b-button>
    </b-button-group>

    <hr v-if="isHairServiceType" />

    <div v-if="isHairServiceType && !isDefaultCombination" class="combination_list">
      <div
        v-for="(fieldType, key) in haircutFields"
        :key="`${type}-${key}`"
        :class="{ disabled: !activated }"
        class="combination_block"
      >
        <CombinationBlockFields
          :use-dynamic-pricing="useDynamicPricing"
          :type="fieldType"
          :name="name"
          :activated="activated"
          :has-add-combination-button="hasAddCombinationButton"
          :fields="$data[`${fieldType}Fields`]"
          :cached-fields="$data[`${fieldType}CacheFields`]"
          :dynamic-fields="$data[`${fieldType}DynamicFields`]"
          :age-values="ageValues"
          :gender-id="genderId"
          @add-first-dynamic-combination="addFirstDynamicCombination"
          @add-dynamic-combination="addDynamicCombination"
          @update-free-age-values-dynamic-fields="updateFreeAgeValuesDynamicFields"
          @delete-dynamic-combination="deleteDynamicCombination"
          @reset-combination="resetCombination"
        >
        </CombinationBlockFields>
      </div>
    </div>

    <div v-else>
      <div>
        <CombinationBlockFields
          :use-dynamic-pricing="useDynamicPricing"
          :use-service-break="useServiceBreak"
          :type="'default'"
          :name="name"
          :activated="activated"
          :has-add-combination-button="hasAddCombinationButton"
          :fields="defaultFields"
          :cached-fields="defaultCacheFields"
          :dynamic-fields="defaultDynamicFields"
          :age-values="ageValues"
          :gender-id="genderId"
          @add-first-dynamic-combination="addFirstDynamicCombination"
          @add-dynamic-combination="addDynamicCombination"
          @update-free-age-values-dynamic-fields="updateFreeAgeValuesDynamicFields"
          @delete-dynamic-combination="deleteDynamicCombination"
          @reset-combination="resetCombination"
        >
        </CombinationBlockFields>
      </div>
    </div>
  </div>
</template>

<script>
import combinationFormFields from './mixins/combinationFormFields'
import CombinationBlockFields from '@/components/salon/services/CombinationBlockFields'
import TimeConstants from '@/_constants/time.js'

import { unionBy, merge, keyBy, values } from 'lodash'

export default {
  name: 'CombinationBlock',
  components: {
    CombinationBlockFields
  },
  mixins: [combinationFormFields],
  props: {
    useDynamicPricing: {
      type: Number,
      default: 0
    },
    combinations: {
      type: Array,
      default: () => []
    },
    selectedCategory: {
      type: Object,
      default: () => ({})
    },
    genderId: {
      type: Number,
      required: true
    },
    ageValues: {
      type: Array,
      required: true
    },
    lengthValues: {
      type: Array,
      required: true
    },
    i18KeyName: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    },
    additionalText: {
      type: String,
      default: ''
    },
    deletedCombinations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      count: 1,
      activate: false,
      activated: false,
      useServiceBreak: false,
      isDefaultCombination: false,
      defaultCacheFields: '',
      shortCacheFields: '',
      mediumCacheFields: '',
      longCacheFields: '',
      defaultDynamicFields: [],
      shortDynamicFields: [],
      mediumDynamicFields: [],
      longDynamicFields: [],
      fieldTypes: ['short', 'medium', 'long', 'default'],
      indexFrom: 0,
      indexTo: 0,
      defaultFreeAgeValues: [],
      shortFreeAgeValues: [],
      mediumFreeAgeValues: [],
      longFreeAgeValues: [],
      lengthTypes: ['Short', 'Medium', 'Long']
    }
  },
  computed: {
    name() {
      return this.$t(this.i18KeyName)
    },
    isHairServiceType() {
      return this.selectedCategory.haircut_params !== undefined ? this.selectedCategory.haircut_params === 1 : false
    },
    hasAddCombinationButton() {
      return (
        this.i18KeyName === 'salon-services.dynamic-form-label.whom_girls' ||
        this.i18KeyName === 'salon-services.dynamic-form-label.whom_boys'
      )
    },
    haircutFields() {
      return this.fieldTypes.slice(0, -1)
    }
  },
  watch: {
    isHairServiceType: {
      handler(newVal) {
        if (newVal) {
          this.isDefaultCombination = false
        } else {
          this.isDefaultCombination = true
        }
      }
    },
    activate: {
      handler(newVal) {
        if (newVal === false) {
          this.changeActivation()
        } else {
          this.activated = newVal
        }
      }
    }
  },
  mounted() {
    if (!this.isHairServiceType) {
      this.isDefaultCombination = true
    }

    this.fieldTypes.forEach((type, key) => {
      this[`${type}FreeAgeValues`] = this.ageValues
      this.setApiDataByFieldType(type, this.ageValues, key)
      this.cachedFieldsByFieldType(type)
    })

    this.setCombitions()
  },
  methods: {
    ageFromDataByTypeAndToValue(toValue, type) {
      return this[`${type}Fields`][10].data.filter(item => item.id >= toValue)
    },
    detectChanges() {
      let hasChange = false
      this.fieldTypes.forEach((type, key) => {
        if (this[`${type}CacheFields`] !== JSON.stringify(this[`${type}Fields`])) {
          hasChange = true
          return
        }
      })

      return hasChange
    },
    deleteDynamicCombination(params) {
      this.setDeletedCombination(params)

      if (params.key !== 0) {
        this[`${params.type}FreeAgeValues`] = values(
          merge(
            keyBy(this[`${params.type}DynamicFields`][params.key - 1][11].data, 'id'),
            keyBy(this[`${params.type}DynamicFields`][params.key][10].data, 'id')
          )
        )

        const fields = this[`${params.type}DynamicFields`][params.key - 1].map(field => {
          if (field.name === `from_${params.type}_${params.key}` || field.name === `to_${params.type}_${params.key}`) {
            field.data = this[`${params.type}FreeAgeValues`]
          }

          return field
        })
      } else {
        this.resetFreeAgeValuesDynamicFields(params.type)
      }

      this[`${params.type}DynamicFields`].splice(params.key, 1)
    },
    resetCombination(params) {
      this.setDeletedCombination(params)

      this[`${params.type}Fields`] = JSON.parse(this[`${params.type}CacheFields`])
    },
    cachedFieldsByFieldType(fieldType) {
      this[`${fieldType}CacheFields`] = JSON.stringify(this[`${fieldType}Fields`])
    },
    setApiDataByFieldType(fieldType, ageValues, key) {
      this[`${fieldType}Fields`].map(field => {
        if (field.name === `from_${fieldType}` || field.name === `to_${fieldType}`) {
          field.data = ageValues
        }
        if (fieldType !== 'default') {
          field.lengthId = this.lengthValues[key].id
        }
      })
    },
    setCombitions() {
      this.fieldTypes.forEach(type => {
        let combination = this.combinations.find(combination => {
          if (combination.parameter_values[0].id === this.genderId) {
            let existLengthParameter = combination.parameter_values.find(parameter =>
              this.lengthTypes.includes(parameter.value)
            )

            if (existLengthParameter) {
              let combinationLength = combination.parameter_values[1].value.toLowerCase()

              if (combinationLength === type) return combination
            } else if (!existLengthParameter && type == 'default') {
              this.isDefaultCombination = true

              return combination
            }
          }
        })

        if (combination !== undefined) {
          this.setCombinationByFieldTypeAndCombination(type, combination)
          this.activate = true
          this.activated = true
        }
      })

      this.combinations.forEach(combination => {
        this.fieldTypes.forEach(type => {
          if (combination.parameter_values[0].id === this.genderId) {
            let existLengthParameter = combination.parameter_values.find(parameter =>
              this.lengthTypes.includes(parameter.value)
            )

            if (existLengthParameter) {
              let combinationLength = combination.parameter_values[1].value.toLowerCase()

              if (combinationLength === type) this.setDynamicCombination(type, combination)
            } else if (!existLengthParameter && type == 'default') this.setDynamicCombination(type, combination)
          }
        })
      })
    },

    setDynamicCombination(fieldType, combination) {
      if (combination !== undefined) {
        let combinationIndex = this.combinations.findIndex(item => item.id === combination.id)
        let { parameter_values, price, min_price, duration, duration_before_break, duration_after_break } = combination
        let fieldsKey = this[`${fieldType}DynamicFields`].length + 1
        let existAgeParameter = parameter_values.find(parameter => !isNaN(Number(parameter.value)))

        const fields = JSON.parse(this[`${fieldType}CacheFields`]).map((field, key) => {
          if (field.name === `from_${fieldType}` || field.name === `to_${fieldType}`) {
            field.data = this[`${fieldType}FreeAgeValues`]
          }

          let genderId = parameter_values[0].id

          if (this.genderId === genderId) {
            // Detect exixt Age values
            if (existAgeParameter) {
              if (field.name === `from_${fieldType}`) {
                // Set age from parameter
                field.value = this.isDefaultCombination ? parameter_values[1].id : parameter_values[2].id
              }
              if (field.name === `to_${fieldType}`) {
                // Set age to parameter
                field.value = parameter_values[parameter_values.length - 1].id
              }
            }

            if (field.name === `min_price_${fieldType}` && Boolean(min_price)) {
              field.value = min_price
            }

            if (field.name === `price_${fieldType}`) {
              field.value = price
            }

            if (field.name === `hours_${fieldType}`) {
              field.value = this.calcHours(duration)
            }

            if (field.name === `minutes_${fieldType}`) {
              field.value = this.calcMinutes(duration)
            }

            if (field.name === `hours_break_${fieldType}` && combination.break !== null) {
              field.value = this.calcHours(combination.break)
            }

            if (field.name === `minutes_break_${fieldType}` && combination.break !== null) {
              field.value = this.calcMinutes(combination.break)
            }

            if (field.name === `hours_duration_before_break_${fieldType}` && duration_before_break !== null) {
              field.value = this.calcHours(duration_before_break)
            }

            if (field.name === `minutes_duration_before_break_${fieldType}` && duration_before_break !== null) {
              field.value = this.calcMinutes(duration_before_break)
            }

            if (field.name === `hours_duration_after_break_${fieldType}` && duration_after_break !== null) {
              field.value = this.calcHours(duration_after_break)
            }

            if (field.name === `minutes_duration_after_break_${fieldType}` && duration_after_break !== null) {
              field.value = this.calcMinutes(duration_after_break)
            }
          }

          return {
            ...field,
            ...{ id: combination.id },
            name: `${field.name}_${fieldsKey}`
          }
        })

        if (existAgeParameter) {
          let fromAge = this.isDefaultCombination ? parameter_values[1].id : parameter_values[2].id

          const ageRange = {
            type: fieldType,
            from: fromAge,
            to: parameter_values[parameter_values.length - 1].id
          }

          this.updateFreeAgeValuesDynamicFields(ageRange)
        }

        this[`${fieldType}DynamicFields`].push(fields)
      }
    },
    setCombinationByFieldTypeAndCombination(fieldType, combination) {
      this[`${fieldType}Fields`].forEach((field, key) => {
        let combinationIndex = this.combinations.findIndex(item => item.id === combination.id)
        let { parameter_values, min_price, price, duration, duration_before_break, duration_after_break } = combination
        let genderId = parameter_values[0].id
        let existAgeParameter = parameter_values.find(parameter => !isNaN(Number(parameter.value)))

        if (this.genderId === genderId) {
          // Detect exist Age values
          if (existAgeParameter) {
            if (field.name === `from_${fieldType}`) {
              // Set age from parameter
              field.value = this.isDefaultCombination ? parameter_values[1].id : parameter_values[2].id
            }
            if (field.name === `to_${fieldType}`) {
              // Set age to parameter
              field.value = parameter_values[parameter_values.length - 1].id
            }
          }

          if (field.name === `min_price_${fieldType}` && Boolean(min_price)) {
            field.value = min_price
          }

          if (field.name === `price_${fieldType}`) {
            field.value = price
          }

          if (field.name === `hours_${fieldType}`) {
            field.value = this.calcHours(duration)
          }

          if (field.name === `minutes_${fieldType}`) {
            field.value = this.calcMinutes(duration)
          }

          if (field.name === `hours_break_${fieldType}` && combination.break !== null) {
            field.value = this.calcHours(combination.break)
          }

          if (field.name === `minutes_break_${fieldType}` && combination.break !== null) {
            field.value = this.calcMinutes(combination.break)
          }

          if (field.name === `hours_duration_before_break_${fieldType}` && duration_before_break !== null) {
            field.value = this.calcHours(duration_before_break)
          }

          if (field.name === `minutes_duration_before_break_${fieldType}` && duration_before_break !== null) {
            field.value = this.calcMinutes(duration_before_break)
          }

          if (field.name === `hours_duration_after_break_${fieldType}` && duration_after_break !== null) {
            field.value = this.calcHours(duration_after_break)
          }

          if (field.name === `minutes_duration_after_break_${fieldType}` && duration_after_break !== null) {
            field.value = this.calcMinutes(duration_after_break)
          }

          if (combination.break && this.isDefaultCombination) {
            this.useServiceBreak = true
          }
        }

        if (key === this[`${fieldType}Fields`].length - 1) {
          if (existAgeParameter) {
            let fromAge = this.isHairServiceType ? parameter_values[2].id : parameter_values[1].id

            const ageRange = {
              type: fieldType,
              from: fromAge,
              to: parameter_values[parameter_values.length - 1].id
            }

            this.updateFreeAgeValuesDynamicFields(ageRange)
          }

          this.combinations.splice(combinationIndex, 1)
        }

        field.id = combination.id
      })
    },
    resetFreeAgeValuesDynamicFields(type) {
      this[`${type}FreeAgeValues`] = this.ageValues
    },
    addFirstDynamicCombination(type) {
      const ageRange = {
        type: type,
        from: this[`${type}Fields`][10].value,
        to: this[`${type}Fields`][11].value
      }

      this.updateFreeAgeValuesDynamicFields(ageRange)
      this.addDynamicCombination(type)
    },
    addDynamicCombination(fieldType) {
      let key = this[`${fieldType}DynamicFields`].length + 1

      const fields = JSON.parse(this[`${fieldType}CacheFields`]).map(field => {
        if (field.name === `from_${fieldType}` || field.name === `to_${fieldType}`) {
          field.data = this[`${fieldType}FreeAgeValues`]
        }

        return { ...field, name: `${field.name}_${key}` }
      })

      this[`${fieldType}DynamicFields`].push(fields)
    },
    updateFreeAgeValuesDynamicFields(ageRange) {
      this.indexFrom = this[`${ageRange.type}FreeAgeValues`].findIndex(value => value.id === ageRange.from)
      this.indexTo = this[`${ageRange.type}FreeAgeValues`].findIndex(value => value.id === ageRange.to)

      this[`${ageRange.type}FreeAgeValues`] = this[`${ageRange.type}FreeAgeValues`].filter(
        (value, key) => key < this.indexFrom || key > this.indexTo
      )
    },
    calcHours(duration) {
      let time = duration / 60

      return time > 0 ? Math.trunc(time) : 0
    },
    calcMinutes(duration) {
      let time = duration / 60
      let minutes = Math.trunc(60 * parseFloat((time - Math.trunc(time)).toFixed(2)))

      if (minutes === 0) {
        minutes = '00'
      }

      return minutes
    },
    setDeletedCombination(params) {
      if (params.id !== undefined) {
        this.deletedCombinations.push({
          id: params.id,
          deleted: '1'
        })
      }
    },
    changeTypeCombination(val) {
      if (this.detectChanges()) {
        let message = {
          title: this.$t('salon-services.confirm.combination_title'),
          subtitle: this.$t('salon-services.confirm.combination_subtitle')
        }

        this.confirRefreshAllMessage(message).then(value => {
          if (value) {
            this.resetAllFields()
            this.isDefaultCombination = val
          }
        })
      } else {
        this.isDefaultCombination = val
      }
    },
    changeActivation() {
      if (this.detectChanges()) {
        let message = {
          title: this.$t('salon-services.confirm.activated_title'),
          subtitle: this.$t('salon-services.confirm.activated_subtitle')
        }

        this.confirRefreshAllMessage(message).then(value => {
          if (value) {
            this.resetAllFields()
            this.activate = false
            this.activated = false
          } else {
            this.activate = true
          }
        })
      } else {
        this.activated = this.activate
      }
    },
    confirRefreshAllMessage(message) {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [message.title]),
        h('p', { class: ['text-center text-small text-black pb-0 mb-0 lh-1-5'] }, [message.subtitle])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    resetAllFields() {
      let blockFieldsComponents = this.$children

      Object.values(blockFieldsComponents).forEach(blockRefs => {
        if (blockRefs.name !== undefined) {
          blockRefs.resetCombinationEmit()

          if (Object.keys(blockRefs.$refs).length > 0) {
            Object.values(blockRefs.$refs).forEach(ref => {
              if (ref !== undefined) {
                Object.values(ref).forEach(block => {
                  block.deleteDynamicCombinationEmit()
                })
              }
            })
          }
        }
      })
    }
  }
}
</script>
