<template>
  <main class="navbar-offset-tablet salon-flow services-page">
    <PageNavigator
      :page-title="
        serviceId ? $t('salon-services.breadcrumbs-update-label') : $t('salon-services.breadcrumbs-create-label')
      "
      :has-back="true"
      :button-back-link="'/salon/services'"
      :class="
        'd-flex justify-content-between align-items-center border-bottom px-1-5 py-0-8 position-fixed bg-white w-100'
      "
      style="z-index: 1001;"
    >
      <template v-slot:action>
        <div class="d-flex align-items-center">
          <button v-if="serviceId" class="btn btn-checkbox px-1-5 py-0-8 mr-1-5" @click="deleteService()">
            <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

            <span class="font-weight-regular roboto-font text-black">
              {{ $t('common.delete-btn') }}
            </span>
          </button>

          <button :disabled="submitProcess" class="btn btn-primary px-4 py-1" @click="submitForm()">
            <span class="font-weight-medium poppins-font">
              {{ $t('common.submit-btn') }}
            </span>
          </button>
        </div>
      </template>
    </PageNavigator>

    <div class="full-page-height">
      <div class="px-1-5 pt-7-5 border-bottom">
        <form>
          <div class="d-flex align-items-center mb-1-5">
            <div id="service-info-1" class="mr-1 border-0 bg-transparent">
              <font-awesome-icon
                class="fa-w-16"
                style="font-size:16px;"
                data-toggle="tooltip"
                :icon="['fas', 'info-circle']"
              ></font-awesome-icon>
            </div>

            <b-tooltip
              target="service-info-1"
              class="mr-1 border-0 bg-transparent"
              triggers="hover click"
              placement="bottom"
              >{{ $t('tooltip_info.services_first') }}</b-tooltip
            >

            <p class="font-weight-medium poppins-font mb-0 text-black">
              {{ $t('salon-services.top-form-label.form-title') }}
            </p>
          </div>

          <div class="row">
            <div v-for="(topField, key) in topFieldsServices" :key="`top-${key}`" class="col">
              <div>
                <label :for="`${topField.name}`" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                  {{ $t(topField.i18KeyLabel) }}
                </label>

                <div class="input-group mb-1-5">
                  <input
                    v-if="topField.tag === 'input' && topField.type === 'text'"
                    :id="topField.name"
                    v-model="topField.value"
                    v-validate="topField.validateRules"
                    :data-vv-as="$t(topField.i18KeyLabel)"
                    :name="topField.name"
                    :type="topField.tyle"
                    :placeholder="$t(topField.i18KeyPlaceholder)"
                    class="form-control w-100 px-1-5"
                  />

                  <select
                    v-if="topField.tag === 'select'"
                    :id="topField.name"
                    v-model="selectedCategoryId"
                    v-validate="topField.validateRules"
                    :data-vv-as="$t(topField.i18KeyLabel)"
                    :name="topField.name"
                    class="form-control w-100 px-1-5 default-appearance"
                    style="font-size:14px;"
                    @change="selectCategory"
                  >
                    <option disabled selected>{{ topField.placeholder }}</option>
                    <option v-for="(option, key) in topField.data" :key="`top-${key}`" :value="option.id">
                      {{ option.title }}
                    </option>
                  </select>

                  <span v-if="errors.has(topField.name)" class="error-message mt-1 col-12 pl-0">
                    {{ errors.first(topField.name) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(bottomField, key) in bottomFieldsServices" :key="`bottom-${key}`">
            <label class="poppзакешированаяins-font text-black mb-0-5 font-weight-medium text-small">
              {{ $t(bottomField.i18KeyLabel) }}
            </label>
            <div class="input-group mb-1-5">
              <textarea
                v-if="bottomField.tag === 'textarea'"
                :id="bottomField.name"
                v-model="bottomField.value"
                :data-vv-as="$t(bottomField.i18KeyLabel)"
                :name="bottomField.name"
                rows="3"
                class="w-100 border rounded"
              ></textarea>
            </div>
          </div>
          <div class="col-12 d-flex w-100 form-group mb-1-5 px-0 pt-0-5 form-check justify-content-center">
            <div class="d-flex flex-column align-items-center">
              <div class="mb-1">
                <div class="checkbox" style="width: 100%; height: 24px;left: -12px;">
                  <input v-model="useDynamicPricing" :true-value="1" :false-value="0" type="checkbox" />
                  <i class="input-helper"></i>
                </div>
              </div>
              <p class="text-black mb-0 poppins-font">
                <span>{{ $t('salon-services.apply_dynamic_price') }}</span>

                <span id="dinamic-prcing-1" class="ml-1 border-0 bg-transparent">
                  <font-awesome-icon
                    class="fa-w-16"
                    style="font-size:16px;"
                    data-toggle="tooltip"
                    :icon="['fas', 'info-circle']"
                  ></font-awesome-icon>
                </span>

                <b-tooltip
                  target="dinamic-prcing-1"
                  class="mr-1 border-0 bg-transparent"
                  triggers="hover click"
                  placement="bottom"
                  >{{ $t('tooltip_info.services_second') }}
                </b-tooltip>
              </p>
            </div>
          </div>
        </form>
      </div>
      <div class="px-1-5 mt-1-5 pb-2">
        <div class="d-flex align-items-center mb-1-5">
          <div id="service-info-2" class="mr-1 border-0 bg-transparent">
            <font-awesome-icon
              class="fa-w-16"
              style="font-size:16px;"
              data-toggle="tooltip"
              :icon="['fas', 'info-circle']"
            ></font-awesome-icon>
          </div>

          <b-tooltip target="service-info-2" class="mr-1 border-0 bg-transparent" triggers="hover" placement="bottom">
            <div>
              <span>{{ $t('tooltip_info.services_third.first_block') }}</span>
              <br />
              <span>{{ $t('tooltip_info.services_third.second_block') }}</span>
              <br />
              <span>{{ $t('tooltip_info.services_third.third_block.example') }}</span>
              <br />
              <span>{{ $t('tooltip_info.services_third.third_block.boys') }}</span>
              <br />
              <span>{{ $t('tooltip_info.services_third.third_block.men') }}</span>
              <br />
              <span>{{ $t('tooltip_info.services_third.fourth_block') }}</span>
            </div>
          </b-tooltip>

          <p class="font-weight-medium poppins-font mb-0 text-black">
            {{ $t('salon-services.dynamic-form-label.form-title') }}
          </p>
        </div>
        <div v-if="loadParams && serviceLoaded && selectedCategory" class="row">
          <div v-for="(combination, key) in combinationList" :key="`combination-${key}`" class="col-md-6">
            <CombinationBlock
              ref="CombinationBlock"
              :use-dynamic-pricing="useDynamicPricing"
              :selected-category="selectedCategory"
              :gender-id="combination.genderId"
              :age-values="combination.ageValues"
              :length-values="combination.lengthValues"
              :i18-key-name="combination.i18KeyName"
              :type="combination.type"
              :additional-text="combination.i18KeyAdditionalText"
              :combinations="combinationsData"
              :deleted-combinations="deletedCombinations"
            >
            </CombinationBlock>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageNavigator from '@/components/PageNavigator'
import CombinationBlock from '@/components/salon/services/CombinationBlock'

import { mapValues, keyBy, map } from 'lodash'

export default {
  i18KeyName: 'SalonServicesForm',
  components: {
    PageNavigator,
    CombinationBlock
  },
  data() {
    return {
      loadParams: false,
      serviceLoaded: false,
      ageValues: [],
      submitProcess: false,
      useDynamicPricing: 0,
      topFieldsServices: [
        {
          i18KeyLabel: 'salon-services.top-form-label.name',
          type: 'text',
          tag: 'input',
          name: 'title',
          value: '',
          validateRules: 'required|min:2|max:40',
          i18KeyPlaceholder: 'salon-services.top-form-label.name_placeholder'
        },
        {
          i18KeyLabel: 'salon-services.top-form-label.category',
          type: '',
          tag: 'select',
          name: 'service_category_id',
          data: [],
          value: '',
          validateRules: 'required',
          i18KeyPlaceholder: 'Please write a description of the salon here'
        }
      ],
      bottomFieldsServices: [
        {
          i18KeyLabel: 'salon-services.top-form-label.description',
          type: 'text',
          tag: 'textarea',
          name: 'description',
          value: '',
          validateRules: 'required|max:9999',
          i18KeyPlaceholder: 'Please write a description of the salon here'
        }
      ],
      combinationList: [
        {
          i18KeyName: 'salon-services.dynamic-form-label.whom_ladies',
          type: 'default',
          i18KeyAdditionalText: ''
        },
        {
          i18KeyName: 'salon-services.dynamic-form-label.whom_gentelmen',
          type: 'default',
          i18KeyAdditionalText: ''
        },
        {
          i18KeyName: 'salon-services.dynamic-form-label.whom_boys',
          type: 'dynamic',
          i18KeyAdditionalText: 'salon-services.dynamic-form-label.additional'
        },
        {
          i18KeyName: 'salon-services.dynamic-form-label.whom_girls',
          type: 'dynamic',
          i18KeyAdditionalText: 'salon-services.dynamic-form-label.additional'
        }
      ],
      createSuccessMessage: {
        type: this.$t('salon-services.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: this.$t('salon-services.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.updated'
      },
      deletedSuccessMessage: {
        type: this.$t('salon-services.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.deleted'
      },
      deleteLastInfoMessage: {
        type: 'info',
        messageI18Key: 'common.alerts.actions.delete_last_info'
      },
      emptyCombinationErorrMessage: {
        type: 'error',
        messageI18Key: 'salon-services.alert.empty_combination_error'
      },
      combinationsPayload: [],
      combinationsData: [],
      deletedCombinations: [],
      selectedCategoryId: null
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    }),
    serviceId() {
      return this.$route.params.id
    },
    selectedCategory() {
      let categoriesData = this.topFieldsServices[1].data
      let selectedValue = this.topFieldsServices[1].value
      let selectedCategory = categoriesData.find(category => category.id === selectedValue)

      return selectedCategory
    }
  },
  mounted() {
    this.loadParams = false

    this.fetchParamsValues().then(response => {
      response.genders.values.forEach((value, key) => {
        this.combinationList[key].genderId = value.id
        this.combinationList[key].ageValues = response.age.values
        this.combinationList[key].lengthValues = response.length.values
        this.ageValues = response.age.values
      })

      this.loadParams = true
    })

    if (this.serviceId !== undefined) {
      this.serviceLoaded = false

      this.fetchServiceDetailsById(this.serviceId).then(response => {
        this.setFormData(response)

        this.serviceLoaded = true
      })
    } else {
      this.serviceLoaded = true
    }

    this.fetchServiceCategories().then(response => {
      this.topFieldsServices.find(field => {
        if (field.name === 'service_category_id') {
          field.data = response.items
        }
      })
    })
  },
  methods: {
    ...mapActions({
      createService: 'services/createService',
      updateService: 'services/updateService',
      fetchServiceCategories: 'services/getServicesCategories',
      fetchServiceDetailsById: 'services/getServiceDetailsById',
      fetchParamsValues: 'haircutParams/getParamsValues',
      deleteSingleServiceById: 'services/deleteSingleServiceById',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    refreshAllCombinations() {
      this.$refs.CombinationBlock.forEach((mainBlock, key) => {
        if (mainBlock.activated) {
          mainBlock.activated = false
          let blockFieldsComponents = mainBlock.$children

          Object.values(blockFieldsComponents).forEach(blockRefs => {
            blockRefs.resetCombinationEmit()

            if (Object.keys(blockRefs.$refs).length > 0) {
              Object.values(blockRefs.$refs).forEach(ref => {
                if (ref !== undefined) {
                  Object.values(ref).forEach(block => {
                    block.deleteDynamicCombinationEmit()
                  })
                }
              })
            }
          })
        }
      })
    },
    submitForm() {
      this.combinationsPayload = []

      this.$refs.CombinationBlock.forEach((mainBlock, key) => {
        if (mainBlock.activated) {
          let blockFieldsComponents = mainBlock.$children

          mainBlock.$validator.validateAll().then(result => {
            if (result) {
              this.colectCombinationPayload(mainBlock)
            }
          })

          Object.values(blockFieldsComponents).forEach(blockRefs => {
            if (Object.keys(blockRefs.$refs).length > 0) {
              Object.values(blockRefs.$refs).forEach(ref => {
                if (ref !== undefined) {
                  Object.values(ref).forEach(block => {
                    block.$validator.validateAll().then(result => {
                      if (result) {
                        const { useServiceBreak, useCombinationBreak } = block
                        const hasBreak = useServiceBreak || useCombinationBreak

                        this.collectFormFields(
                          block.formFields,
                          block.genderId,
                          mainBlock.isDefaultCombination,
                          hasBreak
                        )
                      }
                    })
                  })
                }
              })
            }
          })
        }
      })

      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitProcess = true

          let payload = {
            ...mapValues(keyBy(this.topFieldsServices, 'name'), 'value'),
            ...mapValues(keyBy(this.bottomFieldsServices, 'name'), 'value'),
            ...{ salon_id: this.accountInfo.salon_id },
            ...{ dynamic_pricing: this.useDynamicPricing }
          }

          if (this.$validator.errors.items.length) {
            this.submitProcess = false

            return false
          }

          if (this.combinationsPayload.length === 0) {
            this.submitProcess = false

            this.setError(this.emptyCombinationErorrMessage)
            return false
          }

          let combinations = this.combinationsPayload.concat(this.deletedCombinations)
          payload = { ...payload, ...{ combinations: combinations } }

          if (this.serviceId === undefined) {
            this.createService(payload)
              .then(() => {
                this.submitProcess = false
                this.setSuccess(this.createSuccessMessage)

                this.$router.push('/salon/services')
              })
              .catch(error => {
                this.submitProcess = false
              })
          } else {
            payload = { ...payload, ...{ service_id: this.serviceId } }

            this.updateService(payload)
              .then(() => {
                this.submitProcess = false
                this.setSuccess(this.updateSuccessMessage)

                this.$router.push('/salon/services')
              })
              .catch(error => {
                this.submitProcess = false
              })
          }
        }
      })
    },
    async colectCombinationPayload(block) {
      block.$children.forEach(child => {
        const { fields, genderId, type, useServiceBreak, useCombinationBreak } = child
        const hasBreak = useServiceBreak || useCombinationBreak
        let isDefaultCombination = type === 'default'

        this.collectFormFields(fields, genderId, isDefaultCombination, hasBreak)
      })
    },
    async collectFormFields(fields, genderId, isDefault = false, hasBreak = false) {
      const otherValues = { ...mapValues(keyBy(fields, 'name'), 'value') }
      let comibination = fields.find(field => field.id !== undefined)

      let min_price = Object.entries(otherValues)[0][1]
      let price = Object.entries(otherValues)[1][1]
      let hours = Object.entries(otherValues)[2][1]
      let minutes = Object.entries(otherValues)[3][1]

      let breakHours = Object.entries(otherValues)[4][1]
      let breakMinutes = Object.entries(otherValues)[5][1]
      let beforeBreakHours = Object.entries(otherValues)[6][1]
      let beforeBreakMinutes = Object.entries(otherValues)[7][1]
      let afterBreakHours = Object.entries(otherValues)[8][1]
      let afterBreakMinutes = Object.entries(otherValues)[9][1]

      let ageFrom = Object.entries(otherValues)[10][1]
      let ageTo = Object.entries(otherValues)[11][1]

      let duration = hours * 60 + Number(minutes)
      let breakDuration = breakHours * 60 + Number(breakMinutes)
      let durationBeforeBreak = beforeBreakHours * 60 + Number(beforeBreakMinutes)
      let durationAfterBreak = afterBreakHours * 60 + Number(afterBreakMinutes)
      let totalDurationWithBreak = breakDuration + durationBeforeBreak + durationAfterBreak

      if (hasBreak) {
        duration = totalDurationWithBreak
      }

      if (!price || !duration) {
        return false
      }

      let data = {
        ...{ values: [genderId] },
        ...{ price: Number(price) },
        ...{ duration: duration },
        ...{ id: comibination !== undefined ? comibination.id : null },
        ...{ deleted: null }
      }

      if (this.useDynamicPricing === 1 && Number(min_price) !== 0) {
        data = {
          ...data,
          ...{ min_price: Number(min_price) }
        }
      }

      if (hasBreak) {
        data = {
          ...data,
          ...{ break: breakDuration },
          ...{ duration_before_break: durationBeforeBreak },
          ...{ duration_after_break: durationAfterBreak }
        }
      }

      if (!isDefault) {
        data.values.push(fields[0].lengthId)
      }

      // Detect gender ID === Grils or Boys, validate not empty
      if ((genderId === 3 || genderId === 4) && (ageFrom === '' || ageTo === '')) {
        return false
      }

      // Detect gender ID === Grils or Boys, added to payload ageFrom and ageTo ID
      if (genderId === 3 || genderId === 4) {
        let selectedAges = this.ageValues.filter(age => age.id >= ageFrom && age.id <= ageTo)

        selectedAges.forEach(el => {
          data.values.push(el.id)
        })
      }

      this.combinationsPayload.push(data)
    },
    setFormData(service) {
      this.useDynamicPricing = service.dynamic_pricing

      for (let [key, value] of Object.entries(service)) {
        this.topFieldsServices.find(field => {
          if (key === 'service_category' && field.name === 'service_category_id') {
            this.selectedCategoryId = value.id
            field.value = value.id
          }
          if (field.name === key) field.value = value
        })

        this.bottomFieldsServices.find(field => {
          if (field.name === key) field.value = value
        })
      }

      if (service.combinations !== undefined) {
        this.combinationsData = service.combinations
      }
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          okTitle: 'OK',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess() {
      this.deleteSingleServiceById(this.serviceId)
        .then(response => {
          this.setSuccess(this.deletedSuccessMessage)
          this.$router.push('/salon/services')
        })
        .catch(error => {
          if (error.message === "You can't delete last service") {
            this.setError(this.deleteLastInfoMessage)
          }
        })
    },
    deleteService() {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess()
          }
        })
        .catch(error => {})
    },
    selectCategory(e) {
      if (e.target.value) {
        let message = {
          title: this.$t('salon-services.confirm.category_title'),
          subtitle: this.$t('salon-services.confirm.category_subtitle')
        }

        if (this.topFieldsServices[1].value) {
          this.confirRefreshAllMessage(message).then(value => {
            if (value) {
              this.topFieldsServices[1].value = this.selectedCategoryId
              this.refreshAllCombinations()
            } else {
              this.selectedCategoryId = this.topFieldsServices[1].value
            }
          })
        } else {
          this.topFieldsServices[1].value = this.selectedCategoryId
        }
      }
    },
    confirRefreshAllMessage(message) {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [message.title]),
        h('p', { class: ['text-center text-small text-black pb-0 mb-0 lh-1-5'] }, [message.subtitle])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    }
  }
}
</script>
