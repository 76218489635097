import TimeConstants from "@/_constants/time.js";

export default {
  data() {
    return {
      defaultFields: [
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.min_price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "min_price_default",
          value: "",
          validateRules: "required|min_value:5|decimal:2",
          placeholder: "0",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "price_default",
          value: "",
          validateRules: "required|decimal:2",
          placeholder: "0",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_break_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_break_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_before_break_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_before_break_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_after_break_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_after_break_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.from",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "from_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.to",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "to_default",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "default",
        },
      ],
      shortFields: [
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.min_price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "min_price_short",
          value: "",
          validateRules: "required|min_value:5|decimal:2",
          placeholder: "0",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "price_short",
          value: "",
          validateRules: "required|decimal:2",
          placeholder: "0",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_break_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_break_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_before_break_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_before_break_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_after_break_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_after_break_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.from",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "from_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.to",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "to_short",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "short",
        },
      ],
      mediumFields: [
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.min_price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "min_price_medium",
          value: "",
          validateRules: "required|min_value:5|decimal:2",
          placeholder: "0",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "price_medium",
          value: "",
          validateRules: "required|decimal:2",
          placeholder: "0",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_break_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_break_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_before_break_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_before_break_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_after_break_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_after_break_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.from",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "from_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.to",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "to_medium",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "medium",
        },
      ],
      longFields: [
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.min_price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "min_price_long",
          value: "",
          validateRules: "required|min_value:5|decimal:2",
          placeholder: "0",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.price",
          type: "number",
          min: 0,
          max: 999.99,
          step: 0.01,
          tag: "input",
          name: "price_long",
          value: "",
          validateRules: "required|decimal:2",
          placeholder: "0",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_break_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_break_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_before_break_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_before_break_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.hours",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.HOURS_LIST,
          name: "hours_duration_after_break_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.minutes",
          type: "",
          step: 1,
          tag: "select",
          data: TimeConstants.MINUTES_LIST,
          name: "minutes_duration_after_break_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.from",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "from_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
        {
          lengthId: 0,
          i18KeyLabel: "salon-services.dynamic-form-label.to",
          type: "",
          step: 1,
          tag: "select",
          data: [],
          name: "to_long",
          value: "",
          validateRules: "required",
          placeholder: "00",
          groupFieldType: "long",
        },
      ],
    };
  },
};
