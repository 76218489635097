<template>
  <div v-if="cacheFields.length" class="d-flex flex-wrap w-100">
    <div class="col-12 d-flex justify-content-end">
      <div class="checkbox mb-1-5">
        <label class="mb-0 d-inline-block cursor-pointer">
          <input v-model="useCombinationBreak" type="checkbox" />
          <i class="input-helper"></i>
          <span class="mb-0 text-black" style="line-height: 24px">
            {{ $t('salon-services.dynamic-form-label.break') }}
          </span>
        </label>
      </div>      
    </div>
    <div class="col-12 col-lg-6">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
        {{ $t('salon-services.dynamic-form-label.age_range') }}
      </label>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[10].i18KeyLabel) }}
          </p>
          <div class="input-group mr-0-5 position-relative small-input">
            <select
              v-if="cacheFields[10].tag === 'select'"
              :id="cacheFields[10].name"
              :key="cacheFields[10].name"
              v-model="cacheFields[10].value"
              v-validate="cacheFields[10].validateRules"
              :data-vv-as="$t(cacheFields[10].i18KeyLabel)"
              :name="cacheFields[10].name"
              :disabled="!activatedBlock || disabledSelect"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[10].data" :key="`top-${key}`" :value="option.id">{{
                option.value
              }}</option>
            </select>
          </div>
          <span class="text-gray mx-lg-2-5">—</span>
          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[11].i18KeyLabel) }}
          </p>
          <div class="input-group mr-0-5 position-relative small-input">
            <select
              v-if="cacheFields[11].tag === 'select'"
              :key="cacheFields[11].name"
              :id="cacheFields[11].name"
              v-model="cacheFields[11].value"
              v-validate="cacheFields[11].validateRules"
              :data-vv-as="$t(cacheFields[11].i18KeyLabel)"
              :name="cacheFields[11].name"
              :disabled="!activatedBlock || !cacheFields[10].value || disabledSelect"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in toAgeData()" :key="`top-${key}`" :value="option.id">{{
                option.value
              }}</option>
            </select>
          </div>
        </div>

        <span
          v-if="errors.has(cacheFields[10].name) || errors.has(cacheFields[11].name)"
          class="error-message mt-1 col-12 pl-0"
        >
          {{ errors.first(cacheFields[10].name) || errors.first(cacheFields[11].name) }}
        </span>
      </div>
    </div>

    <div
      v-if="useDynamicPricing && cacheFields[0] !== undefined && cacheFields[0].tag === 'input'"
      :class="[useDynamicPricing === 0 ? 'col-6' : 'col-6 col-lg-3']"
    >
      <div>
        <label :for="`${cacheFields[0].name}`" class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
          $t(cacheFields[0].i18KeyLabel)
        }}</label>

        <div class="input-group" :class="{ 'mb-1-5': !errors.has(cacheFields[0].name) }">
          <div class="input-group-prepend">
            <span class="input-group-text text-gray bg-white px-0-5 py-0-8 text-small font-weight-medium">
              CHF
            </span>
          </div>
          <input
            :id="cacheFields[0].name"
            :key="cacheFields[0].name"
            v-model="cacheFields[0].value"
            v-validate="cacheFields[0].value > 0 ? cacheFields[0].validateRules : ''"
            :min="cacheFields[0].min"
            :max="cacheFields[0].max"
            :step="cacheFields[0].step"
            :data-vv-as="$t(cacheFields[0].i18KeyLabel)"
            :name="cacheFields[0].name"
            :type="cacheFields[0].type"
            :placeholder="cacheFields[0].placeholder"
            :disabled="!activatedBlock"
            class="form-control w-100 px-1-5"
          />
        </div>

        <span v-if="errors.has(cacheFields[0].name)" class="error-message mt-1 col-12 pl-0">{{
          errors.first(cacheFields[0].name)
        }}</span>
      </div>
    </div>

    <div v-if="cacheFields[1].tag === 'input'" :class="[useDynamicPricing === 0 ? 'col-12 col-lg-6' : 'col-6 col-lg-3']">
      <div>
        <label :for="`${cacheFields[1].name}`" class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
          $t(cacheFields[1].i18KeyLabel)
        }}</label>

        <div class="input-group" :class="{ 'mb-1-5': !errors.has(cacheFields[1].name) }">
          <div class="input-group-prepend">
            <span class="input-group-text text-gray bg-white px-0-5 py-0-8 text-small font-weight-medium">
              CHF
            </span>
          </div>
          <input
            :id="cacheFields[1].name"
            :key="cacheFields[1].name"
            v-model="cacheFields[1].value"
            v-validate="
              useDynamicPricing
                ? `${cacheFields[1].validateRules}|min_value:${+cacheFields[0].value}`
                : `${cacheFields[1].validateRules}|min_value:5`
            "
            :min="cacheFields[1].min"
            :max="cacheFields[1].max"
            :step="cacheFields[1].step"
            :data-vv-as="$t(cacheFields[1].i18KeyLabel)"
            :name="cacheFields[1].name"
            :type="cacheFields[1].type"
            :placeholder="cacheFields[1].placeholder"
            :disabled="!activatedBlock"
            class="form-control w-100 px-1-5"
          />
        </div>

        <span v-if="errors.has(cacheFields[1].name)" class="error-message mt-1 col-12 pl-0">{{
          errors.first(cacheFields[1].name)
        }}</span>
      </div>
    </div>

    <div v-if="!useCombinationBreak" class="col-12 col-lg-6">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
        {{ $t('salon-services.dynamic-form-label.duration') }}
      </label>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[2].i18KeyLabel) }}
          </p>
          <div class="input-group mr-0-5 position-relative small-input">
            <select
              v-if="cacheFields[2].tag === 'select'"
              :key="cacheFields[2].name"
              :id="cacheFields[2].name"
              v-model="cacheFields[2].value"
              v-validate="cacheFields[2].validateRules"
              :data-vv-as="$t(cacheFields[2].i18KeyLabel)"
              :name="cacheFields[2].name"
              :disabled="!activatedBlock"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[2].data" :key="`top-${key}`" :value="option">{{
                option
              }}</option>
            </select>
          </div>

          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[3].i18KeyLabel) }}
          </p>
          <div class="input-group mr-0-5 position-relative small-input">
            <select
              v-if="cacheFields[3].tag === 'select'"
              :key="cacheFields[3].name"
              :id="cacheFields[3].name"
              v-model="cacheFields[3].value"
              v-validate="cacheFields[3].validateRules"
              :data-vv-as="$t(cacheFields[3].i18KeyLabel)"
              :name="cacheFields[3].name"
              :disabled="!activatedBlock"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[3].data" :key="`top-${key}`" :value="option">{{
                option
              }}</option>
            </select>
          </div>
        </div>

        <span
          v-if="errors.has(cacheFields[2].name) || errors.has(cacheFields[3].name)"
          class="error-message mt-1 col-12 pl-0"
        >
          {{ errors.first(cacheFields[2].name) || errors.first(cacheFields[3].name) }}
        </span>
      </div>
    </div>

    <div v-if="useCombinationBreak" class="col-12 col-lg-6 col-xl-6">
      <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
        {{ $t('salon-services.dynamic-form-label.duration_break') }}
      </label>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[4].i18KeyLabel) }}
          </p>
          <div class="input-group position-relative small-input">
            <select
              v-if="cacheFields[4].tag === 'select'"
              :key="cacheFields[4].name"
              :id="cacheFields[4].name"
              v-model="cacheFields[4].value"
              v-validate="cacheFields[4].validateRules"
              :data-vv-as="$t(cacheFields[4].i18KeyLabel)"
              :name="cacheFields[4].name"
              :disabled="!activatedBlock"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[4].data" :key="`top-${key}`" :value="option">{{
                option
              }}</option>
            </select>
          </div>

          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[5].i18KeyLabel) }}
          </p>
          <div class="input-group position-relative small-input">
            <select
              v-if="cacheFields[5].tag === 'select'"
              :key="cacheFields[5].name"
              :id="cacheFields[5].name"
              v-model="cacheFields[5].value"
              v-validate="cacheFields[5].validateRules"
              :data-vv-as="$t(cacheFields[5].i18KeyLabel)"
              :name="cacheFields[5].name"
              :disabled="!activatedBlock"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[5].data" :key="`top-${key}`" :value="option">{{
                option
              }}</option>
            </select>
          </div>
        </div>
        <span
          v-if="errors.has(cacheFields[4].name) || errors.has(cacheFields[4].name)"
          class="error-message mt-1 col-12 pl-0"
        >
          {{ errors.first(cacheFields[5].name) || errors.first(cacheFields[5].name) }}
        </span>
      </div>
    </div>

    <div v-if="useCombinationBreak" class="col-12 col-lg-6">
      <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
        {{ $t('salon-services.dynamic-form-label.duration_before_break') }}
      </label>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[6].i18KeyLabel) }}
          </p>
          <div class="input-group position-relative small-input">
            <select
              v-if="cacheFields[6].tag === 'select'"
              :key="cacheFields[6].name"
              :id="cacheFields[6].name"
              v-model="cacheFields[6].value"
              v-validate="cacheFields[6].validateRules"
              :data-vv-as="$t(cacheFields[6].i18KeyLabel)"
              :name="cacheFields[6].name"
              :disabled="!activatedBlock"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[6].data" :key="`top-${key}`" :value="option">{{
                option
              }}</option>
            </select>
          </div>

          <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
            {{ $t(cacheFields[7].i18KeyLabel) }}
          </p>
          <div class="input-group position-relative small-input">
            <select
              v-if="cacheFields[7].tag === 'select'"
              :key="cacheFields[7].name"
              :id="cacheFields[7].name"
              v-model="cacheFields[7].value"
              v-validate="cacheFields[7].validateRules"
              :data-vv-as="$t(cacheFields[7].i18KeyLabel)"
              :name="cacheFields[7].name"
              :disabled="!activatedBlock"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(option, key) in cacheFields[7].data" :key="`top-${key}`" :value="option">{{
                option
              }}</option>
            </select>
          </div>
        </div>

        <span
          v-if="errors.has(cacheFields[6].name) || errors.has(cacheFields[6].name)"
          class="error-message mt-1 col-12 pl-0"
        >
          {{ errors.first(cacheFields[7].name) || errors.first(cacheFields[7].name) }}
        </span>
      </div>
    </div>


      <div v-if="useCombinationBreak" class="col-12 col-lg-5">
        <label class="poppins-font text-black mb-0-5 mt-1 font-weight-medium text-small">
          {{ $t('salon-services.dynamic-form-label.duration_after_break') }}
        </label>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(cacheFields[8].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="cacheFields[8].tag === 'select'"
                :key="cacheFields[8].name"
                :id="cacheFields[8].name"
                v-model="cacheFields[8].value"
                v-validate="cacheFields[8].validateRules"
                :data-vv-as="$t(cacheFields[8].i18KeyLabel)"
                :name="cacheFields[8].name"
                :disabled="!activatedBlock"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in cacheFields[8].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>

            <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-lg-1 mr-md-0-5">
              {{ $t(cacheFields[9].i18KeyLabel) }}
            </p>
            <div class="input-group position-relative small-input">
              <select
                v-if="cacheFields[9].tag === 'select'"
                :key="cacheFields[9].name"
                :id="cacheFields[9].name"
                v-model="cacheFields[9].value"
                v-validate="cacheFields[9].validateRules"
                :data-vv-as="$t(cacheFields[9].i18KeyLabel)"
                :name="cacheFields[9].name"
                :disabled="!activatedBlock"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option v-for="(option, key) in cacheFields[9].data" :key="`top-${key}`" :value="option">{{
                  option
                }}</option>
              </select>
            </div>
          </div>
          <span
            v-if="errors.has(cacheFields[8].name) || errors.has(cacheFields[8].name)"
            class="error-message mt-1 col-12 pl-0"
          >
            {{ errors.first(cacheFields[9].name) || errors.first(cacheFields[9].name) }}
          </span>
        </div>
      </div>

    <div v-if="activatedBlock" class="col-12">
      <div
        class="d-flex justify-content-end mt-1 mt-lg-3 action-combination align-items-center"
        :class="{ 'd-flex': !activatedBlock }"
      >
        <a
          v-if="showAddCombinationButton"
          class="btn btn-checkbox active"
          style="line-height: 24px:"
          @click="addDynamicCombinationEmit()"
        >
          <span class="text-black mr-0-5">
            {{ $t('salon-services.dynamic-form-label.add_button') }}
          </span>
          <font-awesome-icon :icon="['far', 'plus']" class="fa-w-16 text-primary"></font-awesome-icon>
        </a>
        <a
          v-if="isLast"
          class="btn btn-checkbox px-1 text-secondary ml-1 cursor-pointer"
          @click="deleteDynamicCombinationEmit()"
        >
          <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14" style="font-size: 18px;"></font-awesome-icon>
        </a>
      </div>
    </div>

    <div class="col-12 app-fee font-weight-medium">
      <div class="border-bottom mx-n1-5 my-1-5"></div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'DynamicCombinationBlock',
  directives: { mask },
  props: {
    activatedBlock: Boolean,
    isLast: Boolean,
    fieldsGroupKey: Number,
    fieldType: String,
    fieldkey: Number,
    dynamicFiledsLength: Number,
    useDynamicPricing: {
      type: Number,
      default: 0
    },
    genderId: {
      type: Number,
      required: true
    },
    ageValues: {
      type: Array,
      default: () => []
    },
    formFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cacheFields: [],
      useCombinationBreak: false,
      ageRange: {
        from: null,
        to: null,
        type: ''
      }
    }
  },
  computed: {
    showAddCombinationButton() {
      return this.isLast && !this.emptyAgeData && !this.fieldNotSelected && this.fieldsGroupKey !== 15
    },
    fieldNotSelected() {
      const durationKeys = [2,3]
      const breakKeys = [4,5,6,7,8,9]

      return this.cacheFields.filter((el, key) => {
        if (this.useCombinationBreak && durationKeys.includes(key)) {
          return false
        }

        if (!this.useCombinationBreak && breakKeys.includes(key)) {
          return false
        }

        if (this.useDynamicPricing === 0 && key === 0) {
          return false
        } else {
          return el.value === ''
        }
      }).length
    },
    formFieldsString() {
      return JSON.stringify(this.cacheFields)
    },
    emptyAgeData() {
      let fromVal = this.cacheFields[10].value
      let toVal = this.cacheFields[11].value
      let formSelectedIsFirst = this.cacheFields[11].data.findIndex(el => el.id === fromVal) === 0
      let toSelectedIsLast =
        this.cacheFields[11].data.findIndex(el => el.id === toVal) === this.cacheFields[11].data.length - 1

      return formSelectedIsFirst && toSelectedIsLast
    },
    disabledSelect() {
      return this.fieldsGroupKey !== this.dynamicFiledsLength - 1
    }
  },
  watch: {
    activatedBlock(val) {
      if (!val) {
         this.errors.clear()
      }
    },
    useCombinationBreak(val) {
      const durationKeys = [2,3]
      const breakKeys = [4,5,6,7,8,9]

      if (val) {
        durationKeys.forEach(el => this.cacheFields[el].value = "")
      } else {
        breakKeys.forEach(el => this.cacheFields[el].value = "")
      }

      this.$nextTick(()=> this.$validator.validateAll())
    }
  },
  mounted() {
    this.cacheFields = this.formFields
    this.$nextTick(()=>{
      if (this.cacheFields[4].value !== '') {
        this.useCombinationBreak = true
      }
    })
  },
  methods: {
    toAgeData() {
      let fromValue = Number(this.cacheFields[10].value)

      let toData = []
      let toDataOginal = this.cacheFields[11].data

      for (let key in toDataOginal) {
        let previosItem = toDataOginal[key - 1]

        if (toDataOginal[key].id >= fromValue && previosItem !== undefined) {
          let addFromCondition = Number(toDataOginal[key].value) - Number(previosItem.value)

          if (addFromCondition === 1 || Number(toDataOginal[key].id) === fromValue) {
            toData.push(toDataOginal[key])
          } else {
            break
          }
        } else if (toDataOginal[key].id >= fromValue) {
          toData.push(toDataOginal[key])
        }
      }

      return toData
    },
    addDynamicCombinationEmit() {
      const ageRange = {
        type: this.fieldType,
        from: this.cacheFields[10].value,
        to: this.cacheFields[11].value
      }

      this.$emit('update-free-age-values', ageRange)
      this.$emit('add-dynamic-combination')
    },
    deleteDynamicCombinationEmit() {
      let field = this.formFields.find(field => field.id !== undefined)

      let deleteParams = {
        type: this.fieldType,
        key: this.fieldsGroupKey,
        ageData: this.cacheFields[10].data
      }

      if (field !== undefined) {
        deleteParams = {
          ...deleteParams,
          ...{ id: field.id }
        }
      }

      this.$emit('delete-dynamic-combination', deleteParams)
    },
    onSelectAgeEmit(ageRange) {
      this.$emit('update-free-age-values', ageRange)
    }
  }
}
</script>

<style lang="scss" scoped></style>
